body {
  background-color: $black;
}

.event-listing {
  article.event {
    border-bottom: $divider;

    @include small {
      margin-bottom: 32px;
      padding-bottom: 32px;
    }

    a.title-link h2 {
      margin-bottom: 8px;
    }

    .subtitle {
      margin-bottom: 16px;
    }
  }
}

.filters {
  padding-bottom: 24px;
  margin-bottom: 32px;
  border-bottom: $divider;

  @include large {
    padding-bottom: 32px;
    margin-bottom: 40px;
  }
}

.body-inner .site-content-inner {
  padding-top: 24px;
}

.select-session-wrapper,
.select-tickets {
  margin-bottom: 48px;
}

.date-or-flexible-tickets {
  margin-bottom: 40px;
}

.content-detail-page {
  .ticket-group-description-wrapper {
    h2 {
      font: $body-font;
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 8px;
    }
  }

  .ticket-type-description {
    .title {
      font: $body-font;
    }
  }

  .select-tickets {
    .guest-ticket-steppers,
    .member-ticket-steppers {
      > h3 {
        margin-bottom: 20px;
      }
    }

    .ticket-type {
      margin-bottom: 48px;

      &:not(:last-child) {
        border-bottom: $divider;
        margin-bottom: 16px;
        padding-bottom: 16px;

        @include large {
          margin-bottom: 24px;
          padding-bottom: 24px;
        }
      }

      &.fixed-price {
        .price-wrapper {
          font-weight: 300;
        }
      }

      .price-container .price-wrapper.discount {
        color: #6eaadc;
      }
    }

    .ticket-group-wrapper:not(:last-child) {
      border-bottom: 1px solid $light-blue;
      margin-bottom: 48px;
    }
  }

  .selection {
    h2 {
      margin-bottom: 0;
    }
  }

  .description-wrapper .subtitle {
    border-bottom: $divider;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  &.gift-cards {
    .ticket-groups .ticket-types {
      .ticket-type {
        margin-bottom: 24px;

        .radio-description > .label {
          @extend %h3;
        }
      }
    }
  }

  .ticket-type-description {
    .subtitle {
      margin-top: 0;
    }
  }

  .select-tickets {
    margin-bottom: 20px;
  }

  &.membership .select-tickets .section-header {
    margin-top: 14px;
  }
}

.date-first {
  h2 {
    display: none;
  }
}

.stepper {
  button {
    border: 1px solid $light-blue;
    border-radius: 100%;

    svg {
      fill: $very-light-gray;
    }

    &:hover {
      svg {
        fill: $light-blue;
      }
    }
  }
}

section.cart {
  background-color: $dark-blue;

  > h2 {
    background-color: #181e33;
    font-family: $primary-font;
    font-weight: 300;
    font-size: 24px;
    text-transform: uppercase;
  }

  .ticket {
    header {
      h4 {
        font-family: $primary-font;
        font-weight: 300;
        padding-right: 8px;
        line-height: 24px;
      }
    }
  }

  .content {
    .expiry-timer {
      border-bottom: $cart-divider;
      padding-bottom: 16px;

      .expires-in {
        &:not(.error):not(.warning) {
          background-color: #181e33;
          color: $very-light-gray;

          svg {
            fill: $very-light-gray;
          }
        }
      }
    }

    .tickets:not(:first-child) .ticket-wrapper {
      padding-top: 16px;
    }

    .ticket-date {
      font-family: $primary-font;
      font-weight: 300;
      font-size: 13px;
      color: $very-light-gray;
      margin-top: 4px;
    }

    .ticket-type {
      margin-bottom: 0;

      .type-count {
        padding-right: 8px;
      }
    }

    .add-promo-button {
      color: $light-blue;
      text-transform: uppercase;

      &:enabled:hover {
        color: $white;
      }
    }

    .total-prices {
      background-color: transparent;
    }

    h3,
    .price-wrapper {
      font-family: $primary-font;
      font-weight: 300;
    }
  }

  button.primary.purchase {
    font-size: 21px;
    padding: 20px;
    margin: 0;
  }

  div.primary.purchase.branded-button {
    height: 61px;
  }
}

.modal {
  .modal-header {
    .title {
      padding-top: 6px;
    }
  }

  .modal-body {
    background-color: $dark-blue;
  }

  section.cart > .content {
    padding: 16px;

    > div:first-child {
      padding-top: 0;

      .ticket-wrapper {
        padding-top: 0;
      }
    }

    > div:last-child {
      padding-bottom: 0;
    }

    > div,
    .ticket-wrapper {
      padding-left: 0;
      padding-right: 0;
    }

    .expires-in {
      margin: 0;
    }
  }
}

.modal:before {
  background-color: rgba(0, 0, 0, 0.75);
}

.mobile-footer {
  button.secondary,
  button.primary {
    font-size: 15px;
  }
}

.mobile-footer.mobile-cart-footer .total {
  font-weight: 400;
}

input[type='checkbox'] {
  border-radius: 0;
  width: 24px;
  height: 24px;
  border-color: #a9b3cf;

  &:hover {
    border-color: $light-blue;
  }

  &:checked {
    background-color: $light-blue;
    border-color: $light-blue;

    &:before {
      border-color: $black;
      left: 8px;
      top: 3px;
    }
  }
}

.modal {
  .modal-window {
    background-color: #0d101b;
    box-shadow: 3px 5px 20px #000;
  }

  &::backdrop {
    background-color: $black;
  }
}

.checkout-route {
  .mobile-checkout-heading {
    margin-bottom: 32px;
    font-size: 20px;
    padding-top: 25px;
  }
}

.complete {
  .main {
    background-color: $black;
    color: $white;
    padding: 0 24px;

    h1 {
      color: $white;
      font-size: 32px;

      span {
        color: $white;
      }
    }

    code.order-number {
      color: white;
      margin-top: 16px;
      margin-bottom: 32px;

      @include small {
        font-size: 24px;
      }
    }
  }
}

div.message.members-banner.logged-out {
  background-color: transparent;

  a {
    color: $link-color;
  }
}

div.message {
  &:not(.expires-in) {
    &.success,
    &.info,
    &.error,
    &.warning {
      background-color: transparent;
    }
  }

  &.expires-in {
    &.warning,
    &.error {
      color: black;
    }
  }

  &.members-banner.logged-out {
    border: 1px solid $secondary-color;

    svg {
      background-color: $secondary-color;
    }
  }
}

select {
  option {
    background-color: white;
    color: black;
  }
}

.sticky-footer {
  background-color: black;
}

.date-selector,
.select-session-wrapper {
  .selectable-button {
    .dark-theme & {
      border: 1px solid $light-blue;
      background-color: transparent;
      color: white;
      box-shadow: none;
      transition: background-color 0.3s linear;

      .remaining:not(.low-availability) {
        color: rgba(169, 179, 207, 0.5);
      }

      .badge {
        outline: 1px solid white;
      }

      &.selected {
        background-color: transparent;
        outline-width: 1px;
      }

      &.available {
        @include hover {
          background-color: $light-blue;
          color: white;
          border-color: $light-blue;

          .availability-status.danger {
            color: white;
          }
        }
      }

      .availability-status {
        font-weight: 300;
        color: $white;
      }

      &.available {
        .availability-status.danger {
          color: $light-blue;
        }
      }
    }
  }
}

.selected-date-time {
  color: white;
}

.section-header {
  background-color: $dark-blue;

  h3 {
    margin-bottom: 0;
  }
}

.date-picker-wrapper .picker-day.selected,
.date-picker-wrapper .picker-day.focused {
  div {
    color: black;
  }

  &:hover div {
    color: black;
  }
}

.dark-theme .password-requirements {
  &, &.error, &.success {
    background-color: transparent;
  }
}

.dropdown-navigation {
  .vs__dropdown-toggle {
    .dark-theme & {
      box-shadow: 0 1px 6px rgb(255 255 255 / 6%);
      background-color: rgba(255, 255, 255, 0.06);
      outline: 3px solid rgb(255 255 255 / 10%);
    }
  }

  .vs__dropdown-option--selected,
  .vs__dropdown-option--highlight {
    .dark-theme & {
      background-color: rgba(255, 255, 255, 0.06);
      color: white;
    }
  }
}

.session-detail-route {
  .num-tickets {
    background-color: rgba(255, 255, 255, 0.3);
  }
}
